import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import type { TutorialsQuery } from "../../../graphql-types";
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import { HeaderPlaceholder } from "../../styles/ui.styles";

import ReactPlayer from "react-player/lazy";

interface Props {
  data: TutorialsQuery;
}

const Tutorials: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO title={"Tutorials"} description={"Tutorials"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Tutorials</h1>
          {data?.allTutorialsJson?.edges?.map((node) => {
            // do not show if it is a dummy object
            if (node.node.title?.includes("dummy")) return;
            return (
              <TutorialWrapper key={node?.node?.id}>
                <div>
                  <p>{node?.node?.title}</p>
                </div>
                {/* downloadable files */}
                {node?.node?.attachment?.publicURL && (
                  <div>
                    <a
                      href={node.node.attachment.publicURL}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Download
                    </a>
                  </div>
                )}
                {/* youtube videos */}
                {node?.node?.youtube && (
                  <PlayerWrapper>
                    <ReactPlayer
                      controls={true}
                      style={{ position: "absolute", top: 0, left: 0 }}
                      url={node.node.youtube}
                      width="100%"
                      height="100%"
                    />
                  </PlayerWrapper>
                )}
                {/* local videos */}
                {node?.node?.video?.publicURL && (
                  <PlayerWrapper>
                    <ReactPlayer
                      controls={true}
                      style={{ position: "absolute", top: 0, left: 0 }}
                      url={node.node.video.publicURL}
                      width="100%"
                      height="100%"
                    />
                  </PlayerWrapper>
                )}
              </TutorialWrapper>
            );
          })}
        </Wrapper>
      </Layout>
    </>
  );
};

export default Tutorials;

export const data = graphql`
  query Tutorials {
    allTutorialsJson {
      edges {
        node {
          id
          title
          youtube
          video {
            publicURL
          }
          attachment {
            publicURL
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;

const TutorialWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 560px;
  /* border-bottom: 1px solid var(--color-orange); */
  padding: 22px;
  background: #fff;

  border-radius: 22px;
  box-shadow: var(--shadow);
  margin: 25px 0;
  p {
    margin: 0;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    justify-content: center;
    p {
      padding-bottom: 16px;
    }
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  /* height: auto; */
  padding-top: 56.25%; // * aspect ratio: 100 / (1280 / 720)
`;
